import React, { Component } from 'react';
import axios from 'axios';
import { REGULAR_PATH } from '../../env';
import moment from 'moment';
import {
  Segment,
  Header,
  Table,
  Loader,
  Search,
  Modal,
  Input,
  Button,
  Dimmer,
} from 'semantic-ui-react';
import { alertMessage } from '../helper/alert-message';

const INPUT_NAMES = {
  name: 'name',
  mobile: 'mobile',
};
const getToken = () => window.localStorage.getItem('user');

export default class ACIAssets extends Component {
  state = {
    loadingList: true,
    loadingSync: false,
    updatingData: false,
    assetList: [],
    search: '',
    showModal: false,
    modalInitialData: {
      id: '',
      name: '',
      mobile: '',
    },
    modalData: {
      id: '',
      name: '',
      mobile: '',
    },
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    this.setState({ loadingList: true });

    axios
      .get(`${REGULAR_PATH}/assets/aci_assets?access_token=${getToken()}`)
      .then(({ data }) => {
        this.setState({
          loadingList: false,
          assetList: data.data.sort((a, b) => {
            if (!a.modified_at) return 1;
            if (!b.modified_at) return -1;
            return moment(a.modified_at).diff(moment(b.modified_at));
          }),
        });
      })
      .catch(() => {
        this.setState({ loadingList: false });
      });
  };

  handleOpenModal = (id, name, mobile) => {
    const data = {
      id,
      name,
      mobile,
    };
    this.setState({
      showModal: true,
      modalInitialData: data,
      modalData: data,
    });
  };

  handleCloseModal = () => this.setState({ showModal: false });

  handleModalDataChange = (value, type) => {
    const isNumber = /^\d*$/.test(value);

    this.setState((prevState) => ({
      modalData: {
        ...prevState.modalData,
        name: type === INPUT_NAMES.name ? value : prevState.modalData.name,
        mobile:
          type === INPUT_NAMES.mobile && isNumber
            ? value
            : prevState.modalData.mobile,
      },
    }));
  };

  handleSync = () => {
    const { id } = this.state.modalData;
    this.setState({ loadingSync: true });
    axios
      .get(
        `${REGULAR_PATH}/assets/aci_asset_name?asset_id=${id}&access_token=${getToken()}`,
      )
      .then(({ data }) => {
        if (data.status === 'failed') {
          this.setState({ loadingSync: false });
          const message = data.message || '';
          if (message) {
            alertMessage(message);
          }
          return;
        }

        this.setState((prevState) => ({
          loadingSync: false,
          modalData: {
            ...prevState.modalData,
            name: data.asset_name,
          },
        }));
      })
      .catch(() => {
        this.setState({ loadingSync: false });
      });
  };

  handleUpdate = () => {
    const { modalData } = this.state;
    this.setState({ updatingData: true });

    axios
      .patch(
        `${REGULAR_PATH}/assets/aci_assets?access_token=${getToken()}`,
        JSON.stringify({
          asset_id: modalData.id,
          asset_name: modalData.name,
          customer_mobile_number: modalData.mobile,
        }),
      )
      .then(({ data }) => {
        const { assetList, modalData } = this.state;

        const message = data.message || '';

        if (message === 'Update successful.') {
          const updatedList = assetList.map((asset) =>
            asset.asset_id === modalData.id
              ? {
                  ...asset,
                  asset_name: modalData.name,
                  customer_mobile_number: modalData.mobile,
                  modified_at: moment().format('YYYY-MM-DD HH:mm:ss'),
                }
              : asset,
          );

          this.setState({ assetList: updatedList, search: '' });
          this.handleCloseModal();
        }

        if (message) {
          alertMessage(message);
        }

        this.setState({ updatingData: false });
      })
      .catch(() => {});
  };

  render() {
    const {
      loadingList,
      loadingSync,
      updatingData,
      assetList,
      search,
      showModal,
      modalInitialData,
      modalData,
    } = this.state;

    const isAci = ['6020bcbf1d41c84da5bcb6a3'].includes(
      window.localStorage.getItem('organization'),
    );
    const isAdmin = window.localStorage.getItem('org_admin') === 'true';

    if (!isAci || !isAdmin) {
      return <h2>You are not authorized person.</h2>;
    }

    if (loadingList) {
      return (
        <Dimmer active={true}>
          <Loader indeterminate size='big'>
            Loading...
          </Loader>
        </Dimmer>
      );
    }

    const inputsAreSame =
      modalInitialData.name === modalData.name &&
      modalInitialData.mobile === modalData.mobile;
    const isNotValidName = modalData.name.length < 1;
    const isNotValidMobileInput = !/^01[3-9][0-9]{8}$/.test(modalData.mobile);
    const disableUpdate =
      updatingData || inputsAreSame || isNotValidName || isNotValidMobileInput;

    if (showModal) {
      return (
        <Modal
          size='mini'
          closeOnEscape={true}
          closeIcon={true}
          open={showModal}
          onClose={this.handleCloseModal}
        >
          <Modal.Header>Update Asset</Modal.Header>
          <Modal.Content>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <h4 style={{ marginBottom: 5 }}>Asset Name</h4>
                <Input
                  style={{ width: '300px' }}
                  placeholder='Asset Name'
                  action={{
                    content: 'Sync',
                    onClick: this.handleSync,
                    disabled: loadingSync,
                    loading: loadingSync,
                  }}
                  value={modalData.name}
                  onChange={(e) =>
                    this.handleModalDataChange(e.target.value, INPUT_NAMES.name)
                  }
                />
              </div>

              <div style={{ marginTop: 20 }}>
                <h4 style={{ marginBottom: 5 }}>Customer Mobile Number</h4>
                <Input
                  placeholder='Customer Mobile Number'
                  value={modalData.mobile}
                  style={{ width: '300px' }}
                  onChange={(e) =>
                    this.handleModalDataChange(
                      e.target.value,
                      INPUT_NAMES.mobile,
                    )
                  }
                />
              </div>

              <Button
                primary
                style={{ width: '300px', marginTop: 20 }}
                onClick={this.handleUpdate}
                disabled={disableUpdate}
                loading={updatingData}
              >
                Update
              </Button>
            </div>
          </Modal.Content>
        </Modal>
      );
    }

    const filteredList = assetList.filter((item) =>
      Object.values(item).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(search.toLowerCase()),
      ),
    );

    return (
      <Segment>
        <Segment>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header as='h2'>My Assets</Header>
            <div style={{ width: 300 }}>
              <Search
                placeholder='Search here'
                onSearchChange={(_, data) =>
                  this.setState({ search: data.value })
                }
                showNoResults={false}
              />
            </div>
          </div>

          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>No</Table.HeaderCell>
                <Table.HeaderCell>Asset ID</Table.HeaderCell>
                <Table.HeaderCell>Chassis Number</Table.HeaderCell>
                <Table.HeaderCell>Asset Name</Table.HeaderCell>
                <Table.HeaderCell>Customer Mobile Number</Table.HeaderCell>
                <Table.HeaderCell>Modified At</Table.HeaderCell>
                <Table.HeaderCell>Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filteredList.map(
                (
                  {
                    asset_id,
                    chassis_number,
                    asset_name,
                    customer_mobile_number,
                    modified_at,
                  },
                  index,
                ) => (
                  <Table.Row key={asset_id}>
                    <Table.Cell>{index + 1}</Table.Cell>
                    <Table.Cell>{asset_id}</Table.Cell>
                    <Table.Cell>{chassis_number}</Table.Cell>
                    <Table.Cell>{asset_name}</Table.Cell>
                    <Table.Cell>{customer_mobile_number}</Table.Cell>
                    <Table.Cell>
                      {modified_at
                        ? moment(modified_at)
                            .utc(true)
                            .local()
                            .format('DD/MM/YY, H:mm A')
                        : '-'}
                    </Table.Cell>
                    <Table.Cell>
                      <Button
                        primary
                        onClick={() =>
                          this.handleOpenModal(
                            asset_id,
                            asset_name,
                            customer_mobile_number,
                          )
                        }
                      >
                        Update
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ),
              )}
            </Table.Body>
          </Table>
        </Segment>
      </Segment>
    );
  }
}

import { toast, Bounce } from 'react-toastify';

export const alertMessage = function (value = '') {
  toast.info(value, {
    position: 'top-center',
    newestOnTop: true,
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
    theme: 'colored',
    transition: Bounce,
  });
};

import React, { Component } from 'react';
import { Sidebar, Segment, Button, Menu, Icon, Popup } from 'semantic-ui-react';
import Maps from './maps';
import { refreshToken } from './../../helper/refresh-token';
import Details from './details/details';
import { observer, inject } from 'mobx-react';
import StaffPanel from './../admin/staff-panel';
import LocationTable from './location-table';
import { getData, handleMultipleRequest } from './../../helper/dataService.js';
import { Link } from 'react-router-dom';
import { REGULAR_PATH } from '../../../env';
import MobileVerification from '../MobileVerification';
import { alertMessage } from './../../helper/alert-message.js';
import { postData } from '../vms/dataHelper';
import { isCrown } from '../../helper/check-org';
import moment from 'moment';

@inject('user', 'timer')
@observer
class DashboardContainer extends Component {
  constructor(props) {
    super(props);
    this.IntervalID = '';
    this.state = {
      mobileVerificationModal: true,
      visible: false,
      downVisible: false,
      staffPanVisible: false,
      downVisibleMapView: false,
      data: [],
      modalOpen: false,
      id: 0,
      detailVisible: false,
      animateData: null,
      routeData: null,
      userData: null,
      animationRunning: true,
      currentAsset: null,
      assetLiveMode: true,
      showLandMark: false,
      currentLandMarkAsset: null,
      engine_sensor_devices: [],
      position: [],
      landmarks: [],
    };
  }

  componentDidMount() {
    this.IntervalID = setInterval(() => {
      if (this.state.assetLiveMode) {
        this.generateLocationForMarker(this.props.selectedIdToContainer[0]);
      }
    }, 10000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const assetLen = this.props.selectedIdToContainer[0].length;
      this.setState({
        data: this.generateLocationForMarker(
          this.props.selectedIdToContainer[0],
        ),
        currentAsset: null,
        id: assetLen > 0 ? assetLen - 1 : 0,
      });
    }

    if (prevState.data !== this.state.data && this.state.data.length > 0) {
      this.setState({ position: this.state.data[this.state.id].position });
    }

    if (
      JSON.stringify(prevState.position) !== JSON.stringify(this.state.position)
    ) {
      const [lat, ln] = this.state.position;
      postData('location/nearest_landmarks', { ln, lat }).then(({ data }) => {
        const { nearest_landmarks, status } = data;
        if (status === 'success' && nearest_landmarks.length > 0) {
          this.setState({
            landmarks: nearest_landmarks.map((item) => {
              const [lng, lat] = item.loc.coordinates;
              return {
                name: item.name,
                position: [lat, lng],
                distance: item.distance,
              };
            }),
          });
        }
      });
    }

    if (prevState.id !== this.state.id) {
      this.setState({ showLandMark: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.IntervalID);
  }

  mobileVerificationModalHandle = (action) => {
    this.setState({ mobileVerificationModal: action });
  };

  generateLocationForMarker = (selected) => {
    let selectedLength = selected.length;
    let location = [];
    let promises = [];
    if (!localStorage.routeData) {
      for (let i = 0; i < selectedLength; i++) {
        let id = selected[i];
        let url = `${REGULAR_PATH}/assets/${id}/last_data?access_token=`;
        // Last data crown
        if (isCrown()) {
          url = `${REGULAR_PATH}/assets/${id}/last_data_crown?access_token=`;
        }
        promises.push(getData(url));
      }

      let lastDataResponse = handleMultipleRequest(promises);
      lastDataResponse
        .then((results) => {
          results.forEach((res) => {
            window.localStorage.setItem('device_type', res.data.device_type);
            window.localStorage.setItem('device_id', res.data.number);
            let nlm = [];

            if (res.data.acc_connected === 'True') {
              const device_id =
                this.state.engine_sensor_devices.indexOf(res.data.asset_id) ===
                -1
                  ? [...this.state.engine_sensor_devices, res.data.asset_id]
                  : this.state.engine_sensor_devices;
              this.setState({ engine_sensor_devices: device_id });
            }

            nlm.push({ name: 'No Landmark' });

            if (res.data.loc !== undefined) {
              let temp = {};
              let status = res.data.active === 'True' ? 'Active' : 'Inactive';

              let engineState;

              if (
                res.data.last_executed_command === undefined ||
                res.data.last_executed_command === 'on'
              ) {
                engineState = true;
              } else {
                engineState = false;
              }

              temp['name'] = res.data.name;
              temp['position'] = res.data.loc.coordinates
                ? [res.data.loc.coordinates[1], res.data.loc.coordinates[0]]
                : [0.0, 0.0];
              temp['children'] = [
                { speed: Math.round(res.data.speed * 100) / 100 },
                { name: res.data.name },
                {
                  time:
                    res.data.time.length < 25
                      ? moment(res.data.time + '+00:00').format(
                          'dddd, MMMM Do, YYYY, hh:mm:ss A',
                        )
                      : moment(res.data.time).format(
                          'dddd, MMMM Do, YYYY, hh:mm:ss A',
                        ),
                },
                { nlm: nlm },
                { active: status },
                { engine: engineState },
              ];
              temp['assetId'] = res.data._id;
              temp['bearing'] = res.data.bearing;
              temp['device_type'] = res.data.device_type;
              temp['asset_type'] = res.data.asset_type;
              temp['valid_till'] = res.data.valid_till;
              temp['tpms_data'] = res.data.tpms_data;
              temp['tpms_connected'] = res.data.tpms_connected;

              if (res.data.fuel_connected === 'True') {
                temp['fuel_connected'] = res.data.fuel_connected;
                temp['tank_size'] = res.data.tank_size;
                temp['fuel'] = res.data.fuel;
              }

              temp['engine_control'] =
                res.data.engine_control === 'True' ? true : false;

              temp['engine_locked'] =
                res.data.engine_locked === 'True' ||
                res.data.engine_locked === 'False'
                  ? res.data.engine_locked === 'True'
                    ? true
                    : false
                  : null;

              if (res.data.acc_connected === 'True') {
                temp['acc_connected'] = res.data.acc_connected;
              } else {
                temp['acc_connected'] = 'False';
              }

              if (res.data.engine_sensor === 'True') {
                temp['engine_sensor'] = res.data.engine_sensor;
              } else {
                temp['engine_sensor'] = 'False';
              }

              if (res.data.fuel_consumption) {
                temp['fuel_consumption'] = res.data.fuel_consumption;
                temp['fuel_consumption_reset_time'] =
                  res.data.fuel_consumption_reset_time;
                temp['current_daily_fuel_consumption'] =
                  res.data.current_daily_fuel_consumption;
              }

              if (res.data.io_element_status === 'True') {
                temp['io_element_status'] = true;
              }

              temp['video'] = res.data.video === 'True' ? true : false;
              temp['temperature_sensor_1'] =
                res.data.temperature_sensor === 'True' ? true : false;
              temp['temperature_sensor_2'] =
                res.data.temperature_sensor_2 === 'True' ? true : false;
              temp['temperature_sensor_3'] =
                res.data.temperature_sensor_3 === 'True' ? true : false;
              temp['temperature_sensor_4'] =
                res.data.temperature_sensor_4 === 'True' ? true : false;
              temp['temperature_sensor_5'] =
                res.data.temperature_sensor_5 === 'True' ? true : false;
              temp['temperature_sensor_6'] =
                res.data.temperature_sensor_6 === 'True' ? true : false;
              temp['temperature_sensor_7'] =
                res.data.temperature_sensor_7 === 'True' ? true : false;
              temp['temperature_1'] = res.data.temperature;
              temp['temperature_sensor_8'] =
                res.data.temperature_sensor_8 === 'True' ? true : false;
              temp['temperature_1'] = res.data.temperature;
              temp['temperature_2'] = res.data.temperature_2;
              temp['temperature_3'] = res.data.temperature_3;
              temp['temperature_4'] = res.data.temperature_4;
              temp['temperature_5'] = res.data.temperature_5;
              temp['temperature_6'] = res.data.temperature_6;
              temp['temperature_7'] = res.data.temperature_7;
              temp['temperature_8'] = res.data.temperature_8;

              temp['temperature_sensor_name'] =
                res.data.temperature_sensor_name;
              temp['temperature_sensor_2_name'] =
                res.data.temperature_sensor_2_name;
              temp['temperature_sensor_3_name'] =
                res.data.temperature_sensor_3_name;
              temp['temperature_sensor_4_name'] =
                res.data.temperature_sensor_4_name;
              temp['temperature_sensor_5_name'] =
                res.data.temperature_sensor_5_name;
              temp['temperature_sensor_6_name'] =
                res.data.temperature_sensor_6_name;
              temp['temperature_sensor_7_name'] =
                res.data.temperature_sensor_7_name;
              temp['temperature_sensor_8_name'] =
                res.data.temperature_sensor_8_name;

              temp['digital_input_1'] =
                res.data.digital_input_1 === 'True' ? true : false;
              temp['digital_input_2'] =
                res.data.digital_input_2 === 'True' ? true : false;
              temp['digital_input_3'] =
                res.data.digital_input_3 === 'True' ? true : false;

              temp['digital_input_sensor_1'] =
                res.data.digital_input_sensor_1 === 'True' ? true : false;
              temp['digital_input_sensor_2'] =
                res.data.digital_input_sensor_2 === 'True' ? true : false;
              temp['digital_input_sensor_3'] =
                res.data.digital_input_sensor_3 === 'True' ? true : false;

              temp['digital_input_sensor_1_reversed'] =
                res.data.digital_input_sensor_1_reversed === 'True'
                  ? true
                  : false;
              temp['digital_input_sensor_2_reversed'] =
                res.data.digital_input_sensor_2_reversed === 'True'
                  ? true
                  : false;
              temp['digital_input_sensor_3_reversed'] =
                res.data.digital_input_sensor_3_reversed === 'True'
                  ? true
                  : false;

              temp['digital_input_sensor_1_name'] =
                res.data.digital_input_sensor_1_name;
              temp['digital_input_sensor_2_name'] =
                res.data.digital_input_sensor_2_name;
              temp['digital_input_sensor_3_name'] =
                res.data.digital_input_sensor_3_name;

              temp['humidity_sensor_1'] =
                res.data.humidity_sensor_1 === 'True' ? true : false;
              temp['humidity_sensor_2'] =
                res.data.humidity_sensor_2 === 'True' ? true : false;
              temp['humidity_sensor_3'] =
                res.data.humidity_sensor_3 === 'True' ? true : false;
              temp['humidity_sensor_4'] =
                res.data.humidity_sensor_4 === 'True' ? true : false;

              temp['humidity_1'] = res.data.humidity_1;
              temp['humidity_2'] = res.data.humidity_2;
              temp['humidity_3'] = res.data.humidity_3;
              temp['humidity_4'] = res.data.humidity_4;

              if (isCrown()) {
                const {
                  delivery_number = null,
                  chalan_number = null,
                  chalan_quantity = null,
                  destination_address = null,
                  driver_name = null,
                  driver_mobile_number = null,
                  trip_status = null,
                  source_plant_name = null,
                  vehicle_type = null,
                } = res.data;

                const crownData = {
                  delivery_number,
                  chalan_number,
                  chalan_quantity,
                  destination_address,
                  driver_name,
                  driver_mobile_number,
                  trip_status,
                  source_plant_name,
                  vehicle_type,
                };
                temp['crownData'] = crownData;
              }

              location.push(temp);
            } else if (this.props.selectedIdToContainer[0].length === 1) {
              alertMessage('No Location Found');
              clearInterval(this.IntervalID);
            }
          });

          this.setState((prevState) => {
            return {
              data: location,
            };
          });
        })
        .catch((error) => {
          if (error.response.data.error === 'expired token') {
            let extendedToken = refreshToken(
              `${REGULAR_PATH}/assets/${
                selected[0]
              }/last_data?access_token=${window.localStorage.getItem('user')}`,
            );
            extendedToken.then((res) => {
              this.generateLocationForMarker(selected);
            });
          }
        });
      return location;
    }
  };

  toggleVisibility = () => {
    this.setState({
      visible: !this.state.visible,
      downVisible: false,
      downVisibleMapView: false,
      detailVisible: false,
    });
  };

  toggleVisibilityDown = () =>
    this.setState({
      downVisible: !this.state.downVisible,
      downVisibleMapView: false,
    });

  toggleVisibilityDownMapView = () =>
    this.setState({
      downVisibleMapView: !this.state.downVisibleMapView,
      downVisible: false,
    });

  handleChangeState = (selectedAssets, isSubmit) => {
    let markerInfo = this.generateLocationForMarker(selectedAssets);

    this.setState({
      data: markerInfo,
      modalOpen: !isSubmit,
      visible: !isSubmit,
    });
  };

  handleOpenModal = () => {
    this.setState({
      modalOpen: true,
    });
  };

  handleClickMarker = (id) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
    this.setState({
      id: id,
      detailVisible: true,
      staffPanVisible: false,
      visible: false,
      downVisible: false,
      downVisibleMapView: false,
      assetLiveMode: true,
      routeData: [],
      animateData: [],
      showLandMark: false,
    });
  };

  liveModeOn = (mode) => {
    this.setState({
      assetLiveMode: mode,
      routeData: [],
      animateData: [],
      detailVisible: true,
      showLandMark: true,
      currentLandMarkAsset: null,
    });
  };

  handleAnimate = (value, mode) => {
    this.setState({
      animateData: value,
      detailVisible: false,
      assetLiveMode: mode,
      showLandMark: false,
      currentLandMarkAsset: null,
    });
  };

  handleRoute = (value, mode) => {
    this.setState({
      routeData: value,
      animateData: [],
      detailVisible: false,
      assetLiveMode: mode,
      showLandMark: false,
      currentLandMarkAsset: null,
    });
  };

  showLandMark = (showLandMark, id) => {
    this.setState({
      showLandMark: showLandMark,
      detailVisible: true,
      currentLandMarkAsset: id,
      assetLiveMode: true,
      routeData: [],
      animateData: [],
    });
  };

  orgHandler = () => {
    this.setState({
      staffPanVisible: true,
      visible: false,
      detailVisible: false,
      downVisible: false,
    });
  };

  fromStaffPanel = (value) => {
    this.setState({
      userData: value[1],
      staffPanVisible: false,
    });
    this.props.passToDashBoard(value[0]);
  };

  handleAnimationTime = (value) => {
    this.setState({
      animationRunning: !value,
    });
  };

  hideDetailSidebar = () => {
    this.setState({
      detailVisible: false,
      staffPanVisible: false,
      userData: null,
    });
  };

  handleNearVehicle = (data) => {
    let nearLastData = this.generateLocationForMarker(data[0]);
    this.setState({
      data: nearLastData,
      currentAsset: data[1],
    });
  };

  openRightPanel = () => {
    this.setState({
      detailVisible: true,
    });
  };

  moveToLiveReport = () => {
    const element = document.getElementById('live-report');
    element.scrollIntoView();
  };

  render() {
    if (localStorage.routeData) {
      localStorage.removeItem('routeData');
    }
    const {
      visible,
      data,
      id,
      detailVisible,
      staffPanVisible,
      animateData,
      routeData,
      userData,
      animationRunning,
      currentAsset,
      showLandMark,
      currentLandMarkAsset,
      landmarks,
    } = this.state;

    return (
      <div style={{ marginTop: -24, zIndex: -10, marginLeft: -2 }}>
        {window.localStorage.getItem('username') ? null : (
          <Button
            icon='bars'
            style={{
              position: 'fixed',
              top: 8,
              left: 2,
              color: 'white',
              background: '#001529',
              zIndex: 1000,
              padding: '5px',
              fontSize: '26px',
              animation: 'shake 2s 2s',
            }}
            onClick={this.toggleVisibility}
            size='huge'
          />
        )}

        <Sidebar.Pushable as={Segment}>
          <Sidebar
            as={Menu}
            animation='overlay'
            width='thin'
            visible={visible}
            vertical
            style={{ width: '250px', paddingTop: '10px' }}
          >
            {JSON.parse(window.localStorage.getItem('staff')) &&
            JSON.parse(window.localStorage.getItem('allowAccess')) ? (
              <Menu.Item
                name='users'
                onClick={this.orgHandler}
                style={style.staffText}
              >
                <div style={{ display: 'flex', gap: 10 }}>
                  <div>
                    <Icon name='users' />
                  </div>
                  <div>
                    <span>Organizations & users</span>
                  </div>
                </div>
              </Menu.Item>
            ) : null}
            <Menu.Item style={style.staffText}>
              <Link to='/all-reports'>
                <div style={{ display: 'flex', gap: 10 }}>
                  <div>
                    <Icon name='file text' />
                  </div>

                  <div>
                    <span>Reports</span>
                  </div>
                </div>
              </Link>
            </Menu.Item>
            {['54f2cb4b421aa9493557fc09'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='/email-reports'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='envelope' />
                    </div>
                    <div>
                      <span>Email Report</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            )}
            {/* {['5bc6dedd1d41c87634325760'].includes(
              window.localStorage.getItem('organization')
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='vms'>
                  <Icon name='wrench' />
                  <span>Maintenance</span>
                </Link>
              </Menu.Item>
            )}
            {['5638fec0421aa95226c374cf'].includes(
              window.localStorage.getItem('organization')
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='vms/jayson'>
                  <Icon name='wrench' />
                  <span>Maintenance</span>
                </Link>
              </Menu.Item>
            )} */}
            {['5bc6dedd1d41c87634325760'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='vms/transcom'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='wrench' />
                    </div>
                    <div>
                      <span>Maintenance</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            )}

            {['5634fa61421aa95a9fac17c4'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='oil-point'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='flag' />
                    </div>
                    <div>
                      <span>Oil Point</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            )}
            {['5634fa61421aa95a9fac17c4'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='oil-info'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='filter' />
                    </div>
                    <div>
                      <span>Oil Info</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            )}
            <Menu.Item style={style.staffText}>
              <Link to='/geofence'>
                <div style={{ display: 'flex', gap: 10 }}>
                  <div>
                    <Icon name='life ring' />
                  </div>
                  <div>
                    <span>Geofence</span>
                  </div>
                </div>
              </Link>
            </Menu.Item>
            {['66e2a431faf103d2b7178958'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='/temperature'>
                  <Icon name='theme' />
                  <span>Temperature</span>
                </Link>
              </Menu.Item>
            )}
            {['5e646e731d41c8396eabec03'].includes(
              window.localStorage.getItem('organization'),
            ) && (
              <Menu.Item style={style.staffText}>
                <Link to='/syngenta'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='money' />
                    </div>
                    <div>
                      <span>Syngenta</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            )}
            {['5efd6d101d41c855060b1632'].includes(
              window.localStorage.getItem('organization'),
            ) ? (
              <div>
                <Menu.Item style={style.staffText}>
                  <Link to='/asset-panel'>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div>
                        <Icon name='crosshairs' />
                      </div>
                      <div>
                        <span>Asset Panel</span>
                      </div>
                    </div>
                  </Link>
                </Menu.Item>
                <Menu.Item style={style.staffText}>
                  <Link to='/user-panel'>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div>
                        <Icon name='users' />
                      </div>
                      <div>
                        <span>User Panel</span>
                      </div>
                    </div>
                  </Link>
                </Menu.Item>
              </div>
            ) : null}
            {window.localStorage.getItem('org_admin') === 'true' ? (
              <Menu.Item style={style.staffText}>
                <Link to='/live-share'>
                  <div style={{ display: 'flex', gap: 10 }}>
                    <div>
                      <Icon name='share' />
                    </div>
                    <div>
                      <span>Live Share</span>
                    </div>
                  </div>
                </Link>
              </Menu.Item>
            ) : null}
            {['6020bcbf1d41c84da5bcb6a3'].includes(
              window.localStorage.getItem('organization'),
            ) ? (
              <>
                <Menu.Item style={style.staffText}>
                  <Link to='/track-by-code'>
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div>
                        <Icon name='map pin' />
                      </div>
                      <div>
                        <span>Track By Code</span>
                      </div>
                    </div>
                  </Link>
                </Menu.Item>

                {window.localStorage.getItem('org_admin') === 'true' ? (
                  <Menu.Item style={style.staffText}>
                    <Link to='/aci-assets'>
                      <div style={{ display: 'flex', gap: 10 }}>
                        <div>
                          <Icon name='unordered list' />
                        </div>
                        <div>
                          <span>My Assets</span>
                        </div>
                      </div>
                    </Link>
                  </Menu.Item>
                ) : null}
              </>
            ) : null}
          </Sidebar>

          <Sidebar.Pusher style={{ borderRadius: 0 }}>
            <Maps
              data={data}
              id={id}
              route={routeData}
              currentAsset={currentAsset}
              animate={animateData}
              showLandMark={showLandMark}
              currentLandMarkAsset={currentLandMarkAsset}
              sendToDetails={this.handleClickMarker}
              sendAnimationTime={this.handleAnimationTime}
              passRouteData={this.handleRoute}
              makeLiveModeOn={this.liveModeOn}
              landmarks={landmarks}
            />
          </Sidebar.Pusher>

          {data.length > 0 ? (
            !detailVisible ? (
              <div className='open-right-panel' onClick={this.openRightPanel}>
                <Icon
                  disabled
                  name='angle double left'
                  style={{
                    background: 'white',
                    paddingTop: 10,
                    paddingBottom: 30,
                    borderRadius: 5,
                  }}
                />
              </div>
            ) : null
          ) : null}
          {data.length > 0 ? (
            <Sidebar
              animation='push'
              as={Menu}
              className='wide'
              direction='right'
              width='thin'
              visible={detailVisible}
              icon='labeled'
              vertical
            >
              {detailVisible ? (
                <Popup
                  trigger={
                    <Icon
                      name='remove'
                      color='red'
                      circular
                      onClick={this.hideDetailSidebar}
                      style={style.closeButton}
                      className='cross-button'
                    />
                  }
                  content='Close'
                />
              ) : null}
              <br />
              {data.length > 0 ? (
                <Details
                  userData={userData}
                  data={data}
                  id={id}
                  animationRunning={animationRunning}
                  passAnimateData={this.handleAnimate}
                  passRouteData={this.handleRoute}
                  passNearVehicle={this.handleNearVehicle}
                  makeLiveModeOn={this.liveModeOn}
                  showLandMark={this.showLandMark}
                  landmarks={landmarks}
                />
              ) : null}
            </Sidebar>
          ) : null}
          <Sidebar
            as={Menu}
            className='wide'
            animation='push'
            direction='right'
            visible={staffPanVisible}
            icon='labeled'
            vertical
          >
            {staffPanVisible ? (
              <Popup
                trigger={
                  <Icon
                    name='remove'
                    color='red'
                    circular
                    onClick={this.hideDetailSidebar}
                    style={{ ...style.closeButton, zIndex: 10 }}
                    className='cross-button'
                  />
                }
                content='Close'
              />
            ) : null}
            <br />
            <StaffPanel
              staffVisible={staffPanVisible}
              passOrgAssets={this.fromStaffPanel}
            />
          </Sidebar>
        </Sidebar.Pushable>

        {!detailVisible && data.length > 0 ? (
          <div onClick={this.moveToLiveReport} className='open-down-panel'>
            <Icon
              disabled
              name='angle double down'
              style={{
                paddingTop: 10,
                paddingBottom: 30,
                borderRadius: 5,
              }}
            />
          </div>
        ) : null}

        {data.length > 0 ? (
          <LocationTable
            data={data}
            handleClickMarker={this.handleClickMarker}
          />
        ) : null}

        {window.localStorage.getItem('username') ? null : (
          <MobileVerification
            modalOpen={this.state.mobileVerificationModal}
            handleModal={this.mobileVerificationModalHandle}
          />
        )}
      </div>
    );
  }
}

const style = {
  closeButton: {
    marginRight: '10px',
  },
  staffText: {
    marginTop: '5px',
    marginBottom: '5px',
  },
};

export default DashboardContainer;
